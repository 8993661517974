import React from 'react';
import '../styles/components/Breadcrumb.css';

const Breadcrumb = (props) => {

    const breadcrumbLinks = props.breadcrumbData;

    return (
        <div className="an-logo-header__breadcrumb" key="breadcrumbcontainer">
            <div className="an-breadcrumb__container">
                <ul className="an-breadcrumb__list">
                    {breadcrumbLinks && breadcrumbLinks.length ?
                        breadcrumbLinks.map((breadcrumbItem, index) => {
                            return <li className="an-breadcrumb__item" key={index}>
                                <a className="an-breadcrumb__link" {...!breadcrumbItem.active ? { href: breadcrumbItem.link } : ''}>{breadcrumbItem.displayText}</a>
                                {index !== breadcrumbLinks.length - 1 && <span className="divider">›</span>}
                            </li>
                        }) : ''}
                </ul>
            </div>
        </div>
    )
};


export default Breadcrumb;