
import React, { useState } from 'react';
import '../styles/components/Usermodalpopups.css';
import '../styles/pages/Main.css';
import { deleteUser } from '../services/usersService';
import { useMsal } from "../msal-context";
import { apiRequest } from "../Configs/auth-config";
import Spinner from './Spinner.js'
import Useroperationoutput from './Useroperationoutput.js'
import { useTranslation } from "react-i18next";

const Userdeletionpopup = (props) => {

    const { token, getToken } = useMsal();

    const [userDeletionInProgress, setUserDeletionInProgress] = useState(false);
    const [userDeletionSuccessful, setUserDeletionSuccessful] = useState(false);
    const [userDeletionTriggered, setUserDeletionTriggered] = useState(false);
    //const [userDeletioData, setUserDeletioData] = useState(props.userDeletioData);
    //const [userAdminData, setUserAdminData] = useState(props.userAdminData);
    const [t, i18n] = useTranslation('common');

    async function deleteSelectedUser(userId) {
        try {
            setUserDeletionInProgress(true);

            //ensure token is still valid
            let refreshedToken = await getToken(apiRequest, "loginPopup");

            await deleteUser(refreshedToken, userId, null, props.userAdminData.isCompanyAdmin, props.selectedCompany.companyId, props.userAdminData.email).then(
                (response) => {
                    if (response.status === 200 || response.status === 204) {

                        //let responseData = response.data ? response.data : {};
                        //setUsersList(responseData);
                        setUserDeletionInProgress(false);
                        setUserDeletionSuccessful(true);
                        setUserDeletionTriggered(true);

                        handleUserDeleted(userId);

                        setTimeout(() => {
                            props.handleCancel();
                        }, 3000);
                    }
                    else {
                        setUserDeletionInProgress(false);
                        props.onError({ errorCode: response.status, errorMessage: response.errorMessage });
                    }
                });
        } catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'deleteSelectedUser' })
        }
    }

    const handleDelete = () => {
        try {
            if (props.userDeletioData.bpSupplierId) { //user id to be deleted
                let userId = props.userDeletioData.bpSupplierId;
                deleteSelectedUser(userId);
            }
        } catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleDelete' })
        }
    }

    const handleUserDeleted = (userId) => {
        try {
            if (userId) {
                let userListObject = {
                    bpSupplierId: userId
                }

                //add newly created user to parent users list (to avoid calling user query api each time new user is added)
                props.onUserDeleted(userListObject);
            }
        } catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleUserDeleted' })
        }
    }

    return (
        <div className="modal">
            <div className="modal__content-wrapper">
                <div className="modal__overlay"></div>
                <div className={"modal__content " + (userDeletionSuccessful && userDeletionTriggered ? "modal__content_resized" : "")}>
                    {userDeletionInProgress &&
                        <Spinner mode='delete' text={t('loaders.deletingUser')} />
                    }
                    {userDeletionSuccessful && userDeletionTriggered &&
                        <Useroperationoutput mode='delete' user={props.userDeletioData} />
                    }
                    {!userDeletionTriggered &&
                        <div>
                            <div className="modal__close-container">
                                <button className="modal__close" onClick={() => props.handleCancel()} />
                            </div>
                            <h3>{t('popups.userDeleteConfirmation')}</h3>
                            <div style={{ marginTop: '20px' }}>
                                <span className="confirmation-popup-text-small">{t('popups.confirmationMessage')}
                                    <span className="confirmation-popup-text-small-bold">
                                        {(props.userDeletioData.firstName || props.userDeletioData.lastName) ? props.userDeletioData.firstName + ' ' + props.userDeletioData.lastName : props.userDeletioData.userId}
                                    </span>?
                                </span>
                            </div>
                            <div className="modal__footer">
                                <div className="text-right">
                                    <button onClick={() => props.handleCancel()} className="upm-btn btn--primary">{t('popups.cancel')}</button>
                                    <button onClick={() => { handleDelete() }} className="upm-btn btn--secondary upm-btn-group-left-spaced">{t('popups.userDelete')}</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default Userdeletionpopup;