import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import * as Constants from '../constants/globalVars.js'

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

const isLocalDevEnv = window.location.origin && window.location.origin.indexOf(Constants.LOCAL_DEV_ENV) > -1;
const isDevEnv = window.location.origin && (window.location.origin.indexOf(Constants.DEV_ENV) > -1 || window.location.origin.indexOf(Constants.DEV_ENV_WEBAPP) > -1);
const isTestEnv = window.location.origin && (window.location.origin.indexOf(Constants.TEST_ENV) > -1 || window.location.origin.indexOf(Constants.TEST_ENV_STAGING) > -1);
const isProdEnv = window.location.origin && (window.location.origin.indexOf(Constants.PROD_ENV) > -1 || window.location.origin.indexOf(Constants.PROD_ENV_STAGING) > -1);
const appInsightsInstrKey = isDevEnv ? Constants.DEV_AI_INSTR_KEY : (isTestEnv ? Constants.TEST_AI_INSTR_KEY : (isProdEnv ? Constants.PROD_AI_INSTR_KEY : ''));

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: appInsightsInstrKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

if (appInsightsInstrKey)
    appInsights.loadAppInsights();

export { reactPlugin, appInsights };