import React from 'react';
import IconSuccess from "../images/IconSuccess.svg"
import '../styles/components/Useroperationoutput.css';
import { useTranslation } from "react-i18next";

const Useroperationoutput = (props) => {
    const [t, i18n] = useTranslation('common');

    const modeText = props.mode && props.mode === 'delete' ? t('popups.deleted') : (props.mode === 'create' ? t('popups.created') : t('popups.updated'));
    const text = t('loaders.userOperationSuccess').replace("{0}", props.user.firstName + ' ' + props.user.lastName).replace("{1}", modeText);


    return (
        <div className="operation-output-wrapper">
            <img src={IconSuccess} className="icon-success"></img>
            <p className="operation-text">{text}</p>
        </div>

    )
};

export default Useroperationoutput;

