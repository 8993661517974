export function handleRestException(error, response) {
    if (error.response) {
        if (error.response.data) {
            let parsedResponse;

            try { parsedResponse = JSON.parse(error.response.data); } catch (e) { parsedResponse = error.response.data }

            if (parsedResponse.httpCode) { //check if error came from target Azure API
                response.status = parsedResponse.httpCode + (parsedResponse.errorCode ? ' => ' + parsedResponse.errorCode : '');
                response.errorMessage = parsedResponse.MessageEN ? parsedResponse.MessageEN : parsedResponse.message;
            } else if (parsedResponse.statusCode) { //request didn't reach target Azure API - eg. failed on network or authentication problem
                response.status = parsedResponse.statusCode;
                response.errorMessage = parsedResponse.message;
            } else if (parsedResponse.ClassName) { //error came from middleware .net core API
                response.status = '400';
                response.errorMessage = parsedResponse.ClassName + ' ' + parsedResponse.Message;
            }
        } else {
            response.status = error.response.status;
            response.errorMessage = error.response.message;
        }

        return response;
    } else {
        response.status = '400';
        response.errorMessage = error.message ? error.message : '';
        return response;
    }
}