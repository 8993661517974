import React from 'react';
import '../styles/components/Spinner.css';
import { useTranslation } from "react-i18next";

const Spinner = (props) => {

    const [t, i18n] = useTranslation('common');

    return (
        <div className={"spinner-wrapper " + (props.mode === 'delete' ? 'spinner-wrapper-delete' : 'spinner-wrapper-create')}>
            <div className="spinner">
            </div>
            <div className="spinner-wrapper-text">
                <span style={{ display: "block" }}>{props.text}</span>
            </div>
        </div>
    )
};

export default Spinner;