export function validateInputData(inputUserData) {
    let validationOutputMsg = [];

    if (!inputUserData) {
        validationOutputMsg.push("popups.emailMustBePopulated");
        validationOutputMsg.push("popups.firstNameMustBePopulated");
        validationOutputMsg.push("popups.lastNameMustBePopulated");
        validationOutputMsg.push("popups.appMustBePopulated");

        return validationOutputMsg;
    }

    if (!inputUserData.email) {
        validationOutputMsg.push("popups.emailMustBePopulated");
    }

    if (!inputUserData.firstName) {
        validationOutputMsg.push("popups.firstNameMustBePopulated");
    }

    if (!inputUserData.lastName) {
        validationOutputMsg.push("popups.lastNameMustBePopulated");
    }

    if (!inputUserData.applications || inputUserData.applications.length < 1) {
        validationOutputMsg.push("popups.appMustBePopulated");
    }

    if (inputUserData.email && (!validateEmail(inputUserData.email) || inputUserData.email.indexOf('+') > 0)) {
        validationOutputMsg.push("popups.emailStringInvalid");
    }

    return validationOutputMsg && validationOutputMsg.length ? validationOutputMsg : '';
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}