import '../styles/components/Headerselector.css';
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";


const Companyselector = (props) => {
    const [showMenu, setshowMenu] = useState(false);
    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [userData, setUserData] = useState({});
    const [t, i18n] = useTranslation('common');

    let wrapperRef = {};

    const handleClickOutside = (e) => {
        if (wrapperRef && !wrapperRef.contains(e.target)) {
            if (showMenu) {
                setshowMenu(false)
            }
        }
    }

    const ontopNavigationSelectorKeyPress = (e) => {
        //Enter
        if (e.which === 13) {
            e.target.click();
            e.stopPropagation();
            if (!e.target.classList.contains("top-navigation-selector")) {
                setshowMenu(false);
            }
        }
        //Escape
        if (e.which === 27) {
            setshowMenu(false);
        }
    }

    const toggleMenu = () => {
        setshowMenu(!showMenu)
    }

    const setWrapperRef = (node) => {
        wrapperRef = node;
    }

    const changeCompany = (e) => {
        try {
            e.preventDefault();
            var companyid = e.target.attributes.companyid.value;
            var companyname = e.target.attributes.companyname.value;
            var companybpId = e.target.attributes.bpId.value;

            setCompany({ companyId: companyid, companyName: companyname, bpId: companybpId })

            props.handleCompanyChange({ companyId: companyid, companyName: companyname, bpId: companybpId })
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'changeCompany' })
        }
    }

    useEffect(() => {
        try {
            if (props.userData && props.userData.suppliers && props.userData.suppliers.length) {
                setUserData(props.userData);
                setCompanies(() => []);
                let companyItems = [];
                props.userData.suppliers.forEach(supplier => {

                    if (props.userData.isUpmAdmin) {
                        supplier.externalSuppliers.forEach(externalSupplier => {
                            companyItems.push({
                                companyId: externalSupplier.bpSupplierId,
                                bpId: externalSupplier.bpId,
                                companyName: externalSupplier.name
                            });
                        });
                    }
                    else if (props.userData.isCompanyAdmin) {

                        companyItems.push({
                            companyId: supplier.parentSupplier.bpSupplierId,
                            bpId: supplier.parentSupplier.bpId,
                            companyName: supplier.parentSupplier.name
                        });
                    }
                });
                setCompanies(() => companyItems);

                if (!company && companyItems && companyItems.length) {
                    setCompany({ companyId: companyItems[0].companyId, companyName: companyItems[0].companyName, bpId: companyItems[0].bpId });
                    props.handleCompanyChange({ companyId: companyItems[0].companyId, companyName: companyItems[0].companyName, bpId: companyItems[0].bpId })
                }
            }
        }
        catch (err) {
            if (props.onError)
                props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'prefillCompanies' })
        }
    }, [props.userData]);

    //component mount event
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        setMounted(true);
    }, [handleClickOutside, mounted]);

    //component unmount event
    useEffect(() => {
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        }
    }, [handleClickOutside])

    return (
        <div className={"top-navigation-selector" + (showMenu ? " showMenu" : "")} onClick={toggleMenu} onKeyDown={ontopNavigationSelectorKeyPress} tabIndex="0" ref={setWrapperRef}>
            <div className="top-navigation-selector__actual">
                <span className="top-navigation-selector__actual-label">{t('header.company')} {' '}</span>
                <span className="top-navigation-selector__actual-value">{company && company.companyName ? company.companyName : ''}</span>
                {companies && companies.length > 1 &&
                    <div className="navigation__chevron-container">
                        <div className="navigation__chevron"></div>
                    </div>
                }
            </div>
            {companies && companies.length > 1 &&
                <ul className="top-navigation-selector__menu scrollable-selector">
                    {
                        companies && companies.length && companies.map((companyItem, index) => {
                            return (
                                <li key={companyItem.companyId} className={"top-navigation-selector__menu--item" + (companyItem.companyId === company.companyId ? " selected" : "")}><a href="#" onClick={changeCompany} companyname={companyItem.companyName} companyid={companyItem.companyId} bpid={companyItem.bpId} >{companyItem.companyName}</a></li>
                            )
                        })
                    }
                </ul>
            }
        </div>
    )
};

export default Companyselector;