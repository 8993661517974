
import React, { useState, useEffect } from 'react';
import '../styles/components/Usermodalpopups.css';
import '../styles/pages/Main.css';
import { updateUser, getUser, updateUserActiveFlag } from '../services/usersService';
import { useMsal } from "../msal-context";
import { apiRequest } from "../../src/Configs/auth-config";
import Spinner from './Spinner.js'
import Useroperationoutput from './Useroperationoutput.js'
import ReactTooltip from "react-tooltip";
import { validateInputData } from '../helpers/validationHelper.js';
import { useTranslation } from "react-i18next";


const Userupdatepopup = (props) => {

    const { getToken } = useMsal();

    const [userUpdateInProgress, setUserUpdateInProgress] = useState(false);
    const [userUpdateSuccessful, setUserUpdateSuccessful] = useState(false);
    const [userUpdateTriggered, setUserUpdateTriggered] = useState(false);
    const [userUpdateResponseData, setUserUpdateResponseData] = useState();


    const [userUpdateActiveflagInProgress, setUserUpdateActiveflagInProgress] = useState(false);
    //const [userUpdateActiveFlagSuccessful, setUserUpdateActiveFlagSuccessful] = useState(false);
    const [userUpdateActiveFlagTriggered, setUserUpdateActiveFlagTriggered] = useState(false);

    const [inputUserData, setInputUserData] = useState({ firstName: '', lastName: '', email: '', applications: [] });
    const [initialInputUserData, setInitialInputUserData] = useState();
    const [selectedUserDataLoadInProgress, setSelectedUserDataLoadInProgress] = useState(true);
    const [formValidationMessages, setFormValidationMessages] = useState();

    //const [selectedUserDataLoadSuccessful, setSelectedUserDataLoadSuccessful] = useState(false);
    //const [selectedUserData, setSelectedUserData] = useState();
    //const [error, setError] = useState();
    const [t, i18n] = useTranslation('common');


    async function getSelectedUser(userId) {
        try {
            setSelectedUserDataLoadInProgress(true);
            setSelectedUserDataLoadInProgress(true);
            //ensure token is still valid
            let refreshedToken = await getToken(apiRequest, "loginPopup");

            await getUser(refreshedToken, userId, null, props.userAdminData.isCompanyAdmin, props.selectedCompany.companyId, props.userAdminData.email).then(
                (response) => {
                    if (response.status === 200) {
                        let responseData = response.data; //adminusermockdata
                        //setSelectedUserData(responseData);
                        //setSelectedUserDataLoadSuccessful(true);
                        setSelectedUserDataLoadInProgress(false);

                        let initialUserData = getSelectedUserDataForCurrentCompany(responseData);

                        setInitialInputUserData(initialUserData)
                        setInputUserData(initialUserData);
                    }
                    else {
                        //setSelectedUserDataLoadSuccessful(false);
                        setSelectedUserDataLoadInProgress(false);

                        props.onError({ errorCode: response.status, errorMessage: response.errorMessage });
                    }
                });
        } catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'getSelectedUser' })
        }
    }

    async function updateSelectedUser(bodyParams, userId) {
        try {
            setUserUpdateInProgress(true);

            //ensure token is still valid
            let refreshedToken = await getToken(apiRequest, "loginPopup");

            if (!bodyParams) {
                bodyParams = {};
            }

            //if user active flag changed - separate update call needed
            if (initialInputUserData.isActive !== bodyParams.isActive) {
                setUserUpdateActiveflagInProgress(true);

                await updateUserActiveFlag(refreshedToken, userId, bodyParams.isActive, props.userAdminData.isCompanyAdmin, props.selectedCompany.companyId, props.userAdminData.email).then(
                    (response) => {
                        if (response.status === 204) {
                            let responseData = response.data;
                            //setUsersList(responseData);

                            setUserUpdateActiveflagInProgress(false);
                            //setUserUpdateActiveFlagSuccessful(true);
                            setUserUpdateActiveFlagTriggered(true);

                            setTimeout(() => {
                                props.handleCancel();
                            }, 3000);
                        }
                        else {
                            setUserUpdateActiveflagInProgress(false);
                            props.onError({ errorCode: response.status, errorMessage: response.errorMessage });
                        }
                    });
            } else {
                setUserUpdateActiveFlagTriggered(true);
            }

            //isActive property is not needed for user update operation
            delete bodyParams.isActive;

            await updateUser(refreshedToken, userId, bodyParams, props.userAdminData.isCompanyAdmin, props.selectedCompany.companyId, props.userAdminData.email).then(
                (response) => {
                    if (response.status === 200) {
                        let responseData = response.data;

                        setUserUpdateResponseData(responseData);
                        setUserUpdateInProgress(false);
                        setUserUpdateSuccessful(true);
                        setUserUpdateTriggered(true);
                    }
                    else {
                        setUserUpdateInProgress(false);
                        props.onError({ errorCode: response.status, errorMessage: response.errorMessage });
                    }
                });
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'updateSelectedUser' })
        }
    }

    useEffect(() => {
        if (props.userUpdateData && props.userUpdateData.bpSupplierId) {
            getSelectedUser(props.userUpdateData.bpSupplierId);
        }
    }, [props.userUpdateData])

    useEffect(() => {
        try {
            if (userUpdateTriggered && userUpdateActiveFlagTriggered && !userUpdateInProgress && !userUpdateActiveflagInProgress) {
                handleUserUpdated(userUpdateResponseData, !initialInputUserData.isActive);

                setTimeout(() => {
                    props.handleCancel();
                }, 3000);
            }
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'userUpdateHandling' })
        }
    }, [userUpdateTriggered, userUpdateActiveFlagTriggered, userUpdateInProgress, userUpdateActiveflagInProgress])


    const getSelectedUserDataForCurrentCompany = (selectedUserData) => {
        try {
            let userDataForCompany = null;
            if (selectedUserData) {
                let validSupplier = selectedUserData.suppliers && selectedUserData.suppliers.length ? selectedUserData.suppliers.filter(supplier => supplier.parentSupplier.bpSupplierId === props.selectedCompany.companyId) : null;
                validSupplier = validSupplier ? validSupplier[0] : null;
                let userApplications = validSupplier && validSupplier.applications && validSupplier.applications.length ? validSupplier.applications.map(application => { return { applicationId: application.applicationId } }) : [];

                userDataForCompany = {
                    firstName: selectedUserData.firstName,
                    lastName: selectedUserData.lastName,
                    email: selectedUserData.email,
                    isActive: validSupplier.isActive === 'true' ? true : false,
                    applications: userApplications
                };
            }

            return userDataForCompany;
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'getSelectedUserDataForCurrentCompany' })
        }
    }

    const handleUserUpdate = () => {
        try {
            let bodyParams = inputUserData;
            bodyParams.parentSupplier = { bpSupplierId: props.selectedCompany.companyId };

            updateSelectedUser(inputUserData, props.userUpdateData.bpSupplierId);
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleUserUpdate' })
        }
    }

    const handleUserUpdated = (newUserData, currentActiveState) => {
        try {
            if (newUserData && newUserData.suppliers && newUserData.suppliers.length) {
                let userListObject = {
                    bpSupplierId: newUserData.suppliers[0].bpSupplierId,
                    lastName: newUserData.lastName,
                    firstName: newUserData.firstName,
                    email: newUserData.email,
                    isActive: currentActiveState
                }

                //add newly created user to parent users list (to avoid calling user query api each time new user is added)
                props.onUserUpdated(userListObject);
            }
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleUserUpdated' })
        }
    }

    const handleInputFieldChange = (e) => {
        try {
            setInputUserData(() => ({
                ...inputUserData,
                [e.target.name]: e.target.value
            }));
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleInputFieldChange' })
        }
    }

    const handleActiveChange = (e) => {
        try {
            setInputUserData(() => ({
                ...inputUserData,
                isActive: e.target.checked
            }));
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleActiveChange' })
        }
    }

    const handleApplicationSelection = (e) => {
        try {
            let applications = inputUserData && inputUserData.applications ? inputUserData.applications : [];

            if (e.target.checked) {
                applications.push({ applicationId: e.target.name });
            } else {
                applications = applications.filter(function (app) {
                    return app.applicationId !== e.target.name;
                });
            }

            setInputUserData(() => ({
                ...inputUserData,
                applications: applications
            }));
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleApplicationSelection' })
        }
    }

    //input fields validation
    useEffect(() => {
        try {
            let warningMessages = validateInputData(inputUserData);

            //translate wargning messages
            if (warningMessages && warningMessages.length) {
                warningMessages = warningMessages.map(msg => {
                    return msg.indexOf('emailStringInvalid') < 0 ? t(msg) : t(msg).replace('{0}', inputUserData.email);
                });
            }

            setFormValidationMessages(warningMessages);
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'inputFieldsValidation' })
        }
    }, [inputUserData])

    return (
        <div className="modal">
            <div className="modal__content-wrapper">
                <div className="modal__overlay"></div>
                <div className={"modal__content edit " + (userUpdateSuccessful && userUpdateTriggered ? "modal__content_resized" : "")}>
                    {(userUpdateInProgress || userUpdateActiveflagInProgress) &&
                        <Spinner mode='update' text={t('loaders.updatingUser')} />
                    }
                    {selectedUserDataLoadInProgress &&
                        <Spinner mode='update' text={t('loaders.userDataLoading')} />
                    }
                    {userUpdateSuccessful && userUpdateTriggered &&
                        <Useroperationoutput mode='update' user={inputUserData} />
                    }
                    {!userUpdateTriggered &&
                        <div>
                            <div className="modal__close-container">
                                <button className="modal__close" onClick={() => props.handleCancel()} />
                            </div>
                            <h3>{t('popups.userUpdate')}</h3>
                            <div style={{ marginTop: '20px' }}>
                                <label className="input-with-label">
                                    {t('usersList.email')}
                                    <input
                                        key="email"
                                        name="email"
                                        onChange={handleInputFieldChange}
                                        autoComplete="off"
                                        placeholder="useremail@domain.com"
                                        className=""
                                        disabled={true}
                                        value={inputUserData && inputUserData.email ? inputUserData.email : ''}
                                    />
                                </label>
                                <label className="input-with-label required">
                                    <span className="field-label">{t('usersList.firstName')}</span>
                                    <input
                                        key="firstName"
                                        name="firstName"
                                        onChange={handleInputFieldChange}
                                        autoComplete="off"
                                        placeholder=""
                                        className=""
                                        value={inputUserData && inputUserData.firstName ? inputUserData.firstName : ''}
                                    />
                                </label>
                                <label className="input-with-label required">
                                    <span className="field-label">{t('usersList.lastName')}</span>
                                    <input
                                        key="lastName"
                                        name="lastName"
                                        onChange={handleInputFieldChange}
                                        autoComplete="off"
                                        placeholder=""
                                        className=""
                                        value={inputUserData && inputUserData.lastName ? inputUserData.lastName : ''}
                                    />
                                </label>
                                <label className="input-with-label">
                                    {t('popups.activeFlag')}
                                    <input
                                        type="checkbox"
                                        key="isActive"
                                        name="isActive"
                                        onChange={handleActiveChange}
                                        autoComplete="off"
                                        placeholder=""
                                        className="app-list-checkbox"
                                        checked={inputUserData && inputUserData.isActive ? true : false}
                                    />
                                </label>

                                <div className="assigned-apps-section required">
                                    <h5 style={{ display: "inline-block" }}>{t('popups.assignedApplications')}</h5>
                                    <span className="section-label"></span>
                                    {(props.applications && props.applications.length) ?
                                        <div className="assigned-apps-scrollable">
                                            {
                                                props.applications.map((application, index) => {
                                                    let isSelected = inputUserData && inputUserData.applications && inputUserData.applications.length && inputUserData.applications.filter(app => app.applicationId === application.applicationId).length;
                                                    return <label key={index + application.applicationId} className="app-name-label"><input onChange={handleApplicationSelection} className="app-list-checkbox" name={application.applicationId} key={index + application.applicationId} type="checkbox" checked={isSelected} />{application.name ? application.name : application.applicationName}</label>
                                                })
                                            }
                                        </div>
                                        : <p className="no-applications-for-maintenance" key="noApps">{t('popups.noAppsAvailable')}</p>
                                    }
                                </div>
                            </div>
                            <div className="modal__footer">
                                {(formValidationMessages && formValidationMessages.length) &&
                                    <ReactTooltip arrowColor={'#f4f4c7'} multiline={true} offset={{ top: 5, left: 50 }} className="tooltip-theme" id='userCreationTooltip' type='warning' effect='solid'>
                                        {formValidationMessages.map((message, index) => {
                                            return <p key={'msg-' + index} className="warning-message-line">{message}</p>
                                        })}
                                    </ReactTooltip>
                                }
                                <div className="text-right">
                                    <button onClick={() => props.handleCancel()} className="upm-btn btn--primary">{t('popups.cancel')}</button>
                                    <span data-for="userCreationTooltip" data-tip><button onClick={() => { handleUserUpdate() }} className="upm-btn btn--secondary upm-btn-group-left-spaced" disabled={formValidationMessages && formValidationMessages.length} data-for="endProductTooltip" data-tip >{t('popups.updateUser')}</button></span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
};

export default Userupdatepopup;