import '../styles/components/Headerselector.css';
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";


const Applicationselector = (props) => {
    const [showMenu, setshowMenu] = useState(false);
    const [application, setApplication] = useState();
    const [applications, setApplications] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [error, setError] = useState();
    const [userData, setUserData] = useState({});
    const [t, i18n] = useTranslation('common');

    let wrapperRef = {};

    const handleClickOutside = (e) => {
        if (wrapperRef && !wrapperRef.contains(e.target)) {
            if (showMenu) {
                setshowMenu(false)
            }
        }
    }

    const ontopNavigationSelectorKeyPress = (e) => {
        //Enter
        if (e.which === 13) {
            e.target.click();
            e.stopPropagation();
            if (!e.target.classList.contains("top-navigation-selector")) {
                setshowMenu(false);
            }
        }
        //Escape
        if (e.which === 27) {
            setshowMenu(false);
        }
    }

    const toggleMenu = () => {
        setshowMenu(!showMenu)
    }

    const setWrapperRef = (node) => {
        wrapperRef = node;
    }

    const changeApplication = (e) => {
        try {
            e.preventDefault();
            var applicationid = e.target.attributes.applicationid.value;
            var applicationname = e.target.attributes.applicationname.value;
            setApplication({ applicationId: applicationid, applicationName: applicationname })

            props.handleApplicationChange({ applicationId: applicationid, applicationName: applicationname })
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'changeApplication' })
        }
    }

    useEffect(() => {
        try {
            if (props.userData && props.userData.suppliers && props.userData.suppliers.length && props.selectedCompany && props.selectedCompany.companyId) {
                setUserData(props.userData);
                setApplications(() => []);
                setApplication(() => null);
                let applicationItems = [];

                if (props.userData.isUpmAdmin) {
                    props.userData.suppliers.forEach(supplier => {
                        if (supplier.applications && supplier.applications.length) {
                            supplier.applications.forEach(application => {
                                applicationItems.push({
                                    applicationId: application.applicationId,
                                    applicationName: application.name ? application.name : application.applicationName
                                });
                            });
                        }
                    });
                } else if (props.userData.isCompanyAdmin) {
                    props.userData.suppliers.forEach(supplier => {
                        if (supplier.parentSupplier && supplier.parentSupplier.bpSupplierId === props.selectedCompany.companyId) {
                            if (supplier.applications && supplier.applications.length) {
                                supplier.applications.forEach(application => {
                                    applicationItems.push({
                                        applicationId: application.applicationId,
                                        applicationName: application.name ? application.name : application.applicationName
                                    });
                                });
                            }
                        }
                    });
                }

                setApplications(() => applicationItems);

                /* if ((!props.selectedApplication || !props.selectedApplication.applicationId) && applicationItems && applicationItems.length) {
                     setApplication({ applicationId: applicationItems[0].applicationId, applicationName: applicationItems[0].applicationName });
                     props.handleApplicationChange({ applicationId: applicationItems[0].applicationId, applicationName: applicationItems[0].applicationName })
                 }*/
            }
        }
        catch (err) {
            if (props.onError)
                props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'prefillApplications' })
        }

    }, [props.userData, props.selectedCompany]);

    //component mount event
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        setMounted(true);
    }, [handleClickOutside, mounted]);

    //component unmount event
    useEffect(() => {
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        }
    }, [handleClickOutside])



    return (
        <div className={"top-navigation-selector" + (showMenu ? " showMenu" : "")} onClick={toggleMenu} onKeyDown={ontopNavigationSelectorKeyPress} tabIndex="0" ref={setWrapperRef}>
            <div className="top-navigation-selector__actual">
                <span className="top-navigation-selector__actual-label">{t('header.application')}{' '}</span>
                <span className="top-navigation-selector__actual-value">{props.selectedApplication && props.selectedApplication.applicationName ? props.selectedApplication.applicationName : (applications && applications.length ? t('header.selectApplication') : t('header.noApplicationsAvailable'))}</span>
                {applications && applications.length > 0 &&
                    <div className="navigation__chevron-container">
                        <div className="navigation__chevron"></div>
                    </div>
                }
            </div>
            {applications && applications.length > 0 &&
                <ul className="top-navigation-selector__menu scrollable-selector">
                    {
                        applications && applications.length && applications.map((applicationItem, index) => {
                            return (
                                <li key={index + '-' + applicationItem.applicationId} className={"top-navigation-selector__menu--item" + (applicationItem.applicationId === (props.selectedApplication ? props.selectedApplication.applicationId : false) ? " selected" : "")}><a href="#" onClick={changeApplication} applicationname={applicationItem.applicationName} applicationid={applicationItem.applicationId}>{applicationItem.applicationName}</a></li>
                            )
                        })
                    }
                </ul>
            }
        </div>
    )
};

export default Applicationselector;