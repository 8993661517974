export function isCompanyAdmin(userData) {

    if (userData && userData.suppliers && userData.suppliers.length) {
        const parentSupplier = userData.suppliers[0].parentSupplier;
        const supplierUserType = userData.suppliers[0].supplierUserType;

        if (parentSupplier && supplierUserType && supplierUserType.code === "1")
            return true;
    }

    return false;
}

export function isUpmAdmin(userData) {

    if (userData && userData.suppliers && userData.suppliers.length) {
        const externalSuppliers = userData.suppliers[0].externalSuppliers;
        const supplierUserType = userData.suppliers[0].supplierUserType;

        if (externalSuppliers && supplierUserType && supplierUserType.code === "3")
            return true;
    }
    return false;
}


export function isMobileAccess() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    }

    return false;
}