import config from '../Configs/apiCallsConfig';
import rest from '../services/rest';

export async function getUser(token, userId, qeryParams, isCompanyAdmin, parentSupplierId, calledBy) {

    return await rest.get(`${config.apiBaseUrl}/api/users/${userId}`, qeryParams, token, isCompanyAdmin, parentSupplierId, calledBy);

}

export async function getUsers(token, qeryParams, isCompanyAdmin, parentSupplierId, calledBy) {

    return await rest.get(`${config.apiBaseUrl}/api/users`, qeryParams, token, isCompanyAdmin, parentSupplierId, calledBy);

}

export async function createUser(token, bodyParams, isCompanyAdmin, parentSupplierId, calledBy) {

    return await rest.post(`${config.apiBaseUrl}/api/users/create`, bodyParams, token, isCompanyAdmin, parentSupplierId, calledBy);

}

export async function deleteUser(token, userId, queryParams, isCompanyAdmin, parentSupplierId, calledBy) {

    return await rest.remove(`${config.apiBaseUrl}/api/users/delete/${userId}`, queryParams, token, isCompanyAdmin, parentSupplierId, calledBy);

}

export async function updateUser(token, userId, bodyParams, isCompanyAdmin, parentSupplierId, calledBy) {

    return await rest.put(`${config.apiBaseUrl}/api/users/update/${userId}`, bodyParams, token, isCompanyAdmin, parentSupplierId, calledBy);

}

export async function updateUserActiveFlag(token, userId, isActive, isCompanyAdmin, parentSupplierId, calledBy) {

    let url = isActive ? `${config.apiBaseUrl}/api/users/enable/${userId}` : `${config.apiBaseUrl}/api/users/disable/${userId}`;

    return await rest.patch(url, null, token, isCompanyAdmin, parentSupplierId, calledBy);

}