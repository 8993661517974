
import React, { useState, useEffect, useRef } from 'react';
import '../styles/components/Usermodalpopups.css';
import '../styles/pages/Main.css';
import { createUser } from '../services/usersService';
import { useMsal } from "../msal-context";
import { loginRequest, apiRequest } from "../../src/Configs/auth-config";
import Spinner from './Spinner.js'
import Useroperationoutput from './Useroperationoutput.js'
import ReactTooltip from "react-tooltip";
import { validateInputData, validateEmail } from '../helpers/validationHelper.js';
import { useTranslation } from "react-i18next";


const Usercreationpopup = (props) => {

    const { getToken } = useMsal();

    const [userCreationInProgress, setUserCreationInProgress] = useState(false);
    const [userCreationSuccessful, setUserCreationSuccessful] = useState(false);
    const [userCreationTriggered, setUserCreationTriggered] = useState(false);
    const [inputUserData, setInputUserData] = useState();
    const [formValidationMessages, setFormValidationMessages] = useState();
    const [emailAlreadyExistsMessage, setEmailAlreadyExistsMessage] = useState();
    const [t, i18n] = useTranslation('common');

    async function createNewUser(bodyParams) {
        try {
            setUserCreationInProgress(true);

            //ensure token is still valid
            let refreshedToken = await getToken(apiRequest, "loginPopup");

            if (!bodyParams) {
                bodyParams = {};
            }

            await createUser(refreshedToken, bodyParams, props.userAdminData.isCompanyAdmin, props.selectedCompany.companyId, props.userAdminData.email).then(
                (response) => {
                    if (response.status === 200) {
                        let responseData = response.data;

                        setUserCreationInProgress(false);
                        setUserCreationSuccessful(true);
                        setUserCreationTriggered(true);

                        handleUserCreated(responseData);

                        setTimeout(() => {
                            props.handleCancel();
                        }, 3000);
                    }
                    else {
                        setUserCreationInProgress(false);
                        props.onError({ errorCode: response.status, errorMessage: response.errorMessage });
                    }
                });
        }
        catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'createNewUser' })
        }
    }

    const handleUserCreation = () => {
        try {
            let bodyParams = inputUserData;
            bodyParams.parentSupplier = { bpSupplierId: props.selectedCompany.companyId };
            createNewUser(inputUserData);
        } catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleUserCreation' })
        }
    }

    const handleUserCreated = (newUserData) => {
        try {
            if (newUserData && newUserData.suppliers && newUserData.suppliers.length) {
                let userListObject = {
                    bpSupplierId: newUserData.suppliers[0].supplierId,
                    bpId: newUserData.bpId,
                    oid: newUserData.oid,
                    userId: newUserData.suppliers[0].userId,
                    lastName: newUserData.lastName,
                    firstName: newUserData.firstName,
                    email: newUserData.email,
                    supplierUserType: newUserData.suppliers[0].supplierUserType,
                    isActive: newUserData.suppliers[0].isActive,
                    parentSupplier: newUserData.suppliers[0].parentSupplier,
                    supplierRole: newUserData.suppliers[0].supplierRole
                }

                //add newly created user to parent users list (to avoid calling user query api each time new user is added)
                props.onUserCreated(userListObject);
            }
        } catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleUserCreated' })
        }
    }

    const handleInputFieldChange = (e) => {
        try {
            setInputUserData(() => ({
                ...inputUserData,
                [e.target.name]: e.target.value
            }));
        } catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleInputFieldChange' })
        }
    }

    const handleApplicationSelection = (e) => {
        try {
            let applications = inputUserData && inputUserData.applications ? inputUserData.applications : [];

            if (e.target.checked) {
                applications.push({ applicationId: e.target.name });
            } else {
                applications = applications.filter(function (app) {
                    return app.applicationId !== e.target.name;
                });
            }

            setInputUserData(() => ({
                ...inputUserData,
                applications: applications
            }));
        } catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'handleApplicationSelection' })
        }
    }

    //input fields validation
    useEffect(() => {
        try {
            let warningMessages = validateInputData(inputUserData);

            //translate wargning messages
            if (warningMessages && warningMessages.length) {
                warningMessages = warningMessages.map(msg => {
                    return msg.indexOf('emailStringInvalid') < 0 ? t(msg) : t(msg).replace('{0}', inputUserData.email);
                });
            }

            let emailExistsMsg = '';

            //validate if email specified by user isn't already used for other user under the same company
            if (inputUserData && inputUserData.email && validateEmail(inputUserData.email)) {
                if (props.existingUserEmails && props.existingUserEmails.indexOf(inputUserData.email) > -1) {
                    emailExistsMsg = t('popups.userWithEmailExists').replace("{0}", inputUserData.email).replace("{1}", props.selectedCompany.companyName);
                    warningMessages = warningMessages || [];
                    warningMessages.push(emailExistsMsg);
                }
            }

            setFormValidationMessages(warningMessages);
            setEmailAlreadyExistsMessage(emailExistsMsg);
        } catch (err) {
            props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'userInputDataValidation' })
        }

    }, [inputUserData])

    return (
        <div className="modal">
            <div className="modal__content-wrapper">
                <div className="modal__overlay"></div>
                <div className={"modal__content create " + (userCreationSuccessful && userCreationTriggered ? "modal__content_resized" : "")}>
                    {userCreationInProgress &&
                        <Spinner mode='create' text={t('loaders.creatingUser')} />
                    }
                    {userCreationSuccessful && userCreationTriggered &&
                        <Useroperationoutput mode='create' user={inputUserData} />
                    }
                    {!userCreationTriggered &&
                        <div>
                            <div className="modal__close-container">
                                <button className="modal__close" onClick={() => props.handleCancel()} />
                            </div>
                            <h3> {t('popups.userCreate')}</h3>
                            <div style={{ marginTop: '20px' }}>
                                <label className="input-with-label required">
                                    <span className="field-label">{t('usersList.email')}</span>
                                    <input
                                        key="email"
                                        name="email"
                                        onChange={handleInputFieldChange}
                                        autoComplete="off"
                                        placeholder="useremail@domain.com"
                                        className={emailAlreadyExistsMessage ? "invalid-field-value" : ""}
                                    />
                                    {emailAlreadyExistsMessage &&
                                        <span className="field-warning-text">{emailAlreadyExistsMessage}</span>
                                    }
                                </label>
                                <label className="input-with-label required">
                                    <span className="field-label">{t('usersList.firstName')}</span>
                                    <input
                                        key="firstName"
                                        name="firstName"
                                        onChange={handleInputFieldChange}
                                        autoComplete="off"
                                        placeholder=""
                                        className=""
                                    />
                                </label>
                                <label className="input-with-label required">
                                    <span className="field-label">{t('usersList.lastName')}</span>
                                    <input
                                        key="lastName"
                                        name="lastName"
                                        onChange={handleInputFieldChange}
                                        autoComplete="off"
                                        placeholder=""
                                        className=""
                                    />
                                </label>


                                <div className="assigned-apps-section required">
                                    <h5 style={{ display: "inline-block" }}>{t('popups.assignedApplications')}</h5>
                                    <span className="section-label"></span>
                                    {(props.applications && props.applications.length) ?
                                        <div className="assigned-apps-scrollable">
                                            {
                                                props.applications.map((application, index) => {
                                                    return <label key={index + application.applicationId} className="app-name-label"><input onChange={handleApplicationSelection} className="app-list-checkbox" name={application.applicationId} key={application.applicationId} type="checkbox" />{application.name ? application.name : application.applicationName}</label>
                                                })
                                            }
                                        </div>
                                        : <p className="no-applications-for-maintenance" key="noApps">{t('popups.noAppsAvailable')}</p>
                                    }
                                </div>


                            </div>
                            <div className="modal__footer">
                                {(formValidationMessages && formValidationMessages.length) &&
                                    <ReactTooltip arrowColor={'#f4f4c7'} multiline={true} offset={{ top: 5, left: 50 }} className="tooltip-theme" id='userCreationTooltip' type='warning' effect='solid'>
                                        {formValidationMessages.map((message, index) => {
                                            return <p key={'msg-' + index} className="warning-message-line">{message}</p>
                                        })}
                                    </ReactTooltip>
                                }
                                <div className="text-right">
                                    <button onClick={() => props.handleCancel()} className="upm-btn btn--primary">{t('popups.cancel')}</button>
                                    <span data-for="userCreationTooltip" data-tip><button onClick={() => { handleUserCreation() }} disabled={formValidationMessages && formValidationMessages.length} className="upm-btn btn--secondary upm-btn-group-left-spaced">{t('popups.userCreate')}</button></span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default Usercreationpopup;