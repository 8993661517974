import React, { useEffect } from 'react';
import '../styles/components/Error.css';
import '../styles/pages/Main.css';
import upmError from "../images/upm_error.jpg"
import { useTranslation } from "react-i18next";
import { useAppInsightsContext, useTrackMetric, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

const Errorcomponent = (props) => {

    const [t, i18n] = useTranslation('common');
    const appInsights = useAppInsightsContext();

    useEffect(() => {
        if (appInsights) {
            let err = new Error('Client side error: ' + props.error.errorCode + ': ' + props.error.errorMessage);
            appInsights.trackException(
                {
                    exception: err,
                    severityLevel: SeverityLevel.Error,
                    properties: { component: "UI", method: props.error.stack, err }
                }
            );
        }
    }, [props.errorCode, props.error.errorMessage])

    return (
        <div className="container-sub error_page_section content-centered">
            <div className="hero-wrap">
                <div className="hero hero--on-dark hero--scale">
                    <img alt="Hoida mets&amp;#228;&amp;#228;si" src={upmError} className="hero__content-image" />
                    <div className="hero__content-wrapper">
                        <div className="hero__content-text">
                            <h1 className="hero__header">{t('errorPage.sthWentWrong')} :(</h1>
                            <p className="ingress">
                                {t('errorPage.errorCode')} {props.error.errorCode} <br />
                                {t('errorPage.errorMessage')} {props.error.errorMessage}<br />
                                {props.error.stack &&
                                    <span className="error-page-section-text-small">{t('errorPage.stack')} {props.error.stack} </span>
                                }
                            </p>
                            <div className="hero__link-container">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Errorcomponent;