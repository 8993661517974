import React, { useState, useEffect } from 'react';
import Home from './Home.js'
import Header from './Header.js'
import Errorcomponent from './Error.js'
import Footer from './Footer.js'
import UsersList from './Userslist.js'
import Login from './Login.js'
import Help from './Help.js'
import { Switch, Route, HashRouter, useHistory, BrowserRouter } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

import '../styles/UPM_main.css';
import '../styles/pages/Main.css';
import { useMsal } from "../msal-context";
import { loginRequest, apiRequest } from "../../src/Configs/auth-config";
import { getUser } from '../services/usersService';
import { isCompanyAdmin, isUpmAdmin } from '../helpers/authorizationHelper'
import { generateRequestId } from '../helpers/numericHelpers.js';
import adminusermockdata from '../constants/adminusermockdata.json';

function Main() {

    const { token, getToken, isAuthenticated, loading, user } = useMsal();
    const [userData, setuserData] = useState(null);
    const [userDataLoading, setuserDataLoading] = useState(false);
    const [selectedApplication, setSelectedApplication] = useState('');
    const [selectedCompany, setSelectedCompany] = useState({});
    const [initialLoadingError, setInitialLoadingError] = useState(null);
    const [t, i18n] = useTranslation('common');

    async function getCurrentUser() {
        try {
            setuserDataLoading(true);
            //ensure token is still valid
            let refreshedToken = await getToken(apiRequest, "loginPopup");
            await getUser(refreshedToken, 'me', [], false, '', user.username).then(
                (response) => {
                    if (response && response.status === 200) {
                        //let responseData = adminusermockdata;
                        let responseData = response.data;
                        responseData.isCompanyAdmin = isCompanyAdmin(responseData);
                        responseData.isUpmAdmin = isUpmAdmin(responseData);
                        setuserData(responseData);
                        setuserDataLoading(false);
                    } else if (response && (response.status === 401 || response.status === 400 || response.status.indexOf('401') > -1)) { //user doesn't have FORIT profile at all...
                        let responseData = {};
                        responseData.isCompanyAdmin = false;
                        responseData.isUpmAdmin = false;
                        setuserData(responseData);
                        setuserDataLoading(false);
                    }
                    else {
                        setInitialLoadingError({ errorCode: response.status, errorMessage: response.errorMessage })
                    }
                });
        } catch (error) {
            setInitialLoadingError({ errorCode: 'UI Error', errorMessage: error.message, stack: error.stack })
        }
    }

    const handleCompanyChange = (company) => {
        setSelectedCompany(company);
        setSelectedApplication(null)
    }

    const handleApplicationChange = (application) => {
        setSelectedApplication(application)
    }

    const handleError = (error) => {
        setInitialLoadingError({ errorCode: error.errorCode, errorMessage: error.errorMessage, stack: error.stack })
    }


    useEffect(() => {
        if (token && !userData) {
            getCurrentUser();
        }
    }, [token])

    if (initialLoadingError) {
        return (
            <AppInsightsContext.Provider value={reactPlugin}>
                <BrowserRouter>
                    <div id="app" key="app" className="app">
                        <div className="header-and-content header-and-content--one-row-offset">
                            <Header userData={userData} selectedCompany={selectedCompany} selectedApplication={selectedApplication} onCompanyChange={handleCompanyChange} onApplicationChange={handleApplicationChange} />
                            <Errorcomponent error={initialLoadingError} />
                        </div>
                        <Footer />
                    </div>
                </BrowserRouter>
            </AppInsightsContext.Provider>
        );
    }

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <React.Fragment>

                <div id="app" key="app" className="app">
                    <BrowserRouter>
                        <div className="header-and-content header-and-content--one-row-offset">
                            <Header userData={userData} onError={handleError} selectedCompany={selectedCompany} selectedApplication={selectedApplication} onCompanyChange={handleCompanyChange} onApplicationChange={handleApplicationChange} />
                            <Switch>
                                <Route
                                    exact
                                    path="/home"
                                    render={(props) =>
                                        <Home userData={userData} userDataLoading={userDataLoading} selectedCompany={selectedCompany} selectedApplication={selectedApplication} />
                                    }
                                />
                                <Route
                                    exact
                                    path="/userslist"
                                    render={(props) =>
                                        <UsersList userData={userData} selectedCompany={selectedCompany} selectedApplication={selectedApplication} />
                                    }
                                />
                                <Route
                                    exact
                                    path="/help"
                                    render={(props) =>
                                        <Help />
                                    }
                                />
                                <Route
                                    exact
                                    path="/error"
                                    render={(props) =>
                                        <Errorcomponent error={initialLoadingError} />
                                    }
                                />
                                <Route
                                    exact
                                    path="/"
                                    render={(props) =>
                                        <Login />
                                    }
                                />
                                <Redirect from='/' to='/'></Redirect>
                            </Switch>
                        </div>
                        <Footer />
                    </BrowserRouter>
                </div>
            </React.Fragment>
        </AppInsightsContext.Provider>
    )
}

export default Main;
