export const LOCAL_DEV_ENV = "localhost";

export const DEV_ENV = "dev.admin.upmmetsa.fi";

export const DEV_ENV_WEBAPP = "upmadmintool-dev.azurewebsites.net";

export const TEST_ENV = "test.admin.upmmetsa.fi";

export const TEST_ENV_STAGING = "app-webadmintool-qa-we-001-staging.azurewebsites.net";

export const PROD_ENV = "admin.upmmetsa.fi";

export const PROD_ENV_STAGING = "app-webadmintool-prod-we-001-staging.azurewebsites.net"

export const DEV_TEST_SCOPE = ["29340847-678d-49c6-b655-7fb44b5a0415/testscope"];

export const PROD_SCOPE = ["ce61c5d8-7239-48a0-9188-eb8235340a2e/production"];

export const DEV_TEST_CLIENT_ID = "29340847-678d-49c6-b655-7fb44b5a0415";

export const PROD_CLIENT_ID = "ce61c5d8-7239-48a0-9188-eb8235340a2e";

export const TENANT_ID = "9eab37f0-91c6-47e3-9c00-fe8544bd272e";

export const DEV_AI_INSTR_KEY = "1661d07c-679f-4af9-9950-14cbcf6eb3d5";

export const TEST_AI_INSTR_KEY = "b681c52d-4586-419c-bf62-3fa735efa5e6";

export const PROD_AI_INSTR_KEY = "316d100a-1665-408e-8e35-113064552df6";