import axios from 'axios';
import { generateRequestId } from '../helpers/numericHelpers.js';
import { handleRestException } from '../helpers/exceptionHandlingHelper.js';
import config from '../Configs/apiCallsConfig';

async function get(url, queryParams, token, isCompanyAdmin, parentSupplierId, calledBy) {

    if (!queryParams) {
        queryParams = [];
    }

    //add mandatory reqId param
    queryParams.push({ name: 'reqId', value: generateRequestId() });

    if (calledBy) {
        queryParams.push({ name: 'calledBy', value: calledBy });
    }


    if (queryParams && queryParams.length) {
        url += '?';
        queryParams.forEach((queryParam, index) => {
            url = url + queryParam.name + '=' + queryParam.value + (index >= queryParams.length - 1 ? '' : '&');
        });
    }

    let customHeaders = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
    }

    if (isCompanyAdmin && parentSupplierId)
        customHeaders.bpsupplierparentid = parentSupplierId;

    //console.log('FINAL URL: ' + url);

    let response = {};
    response = await axios.get(url, {
        method: 'GET',
        timeout: config.requestTimeout,
        headers: customHeaders
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return handleRestException(error, response);
        });

    return response;
}

async function post(url, bodyParams, token, isCompanyAdmin, parentSupplierId, calledBy) {
    //console.log('FINAL URL: ' + url);

    url = url + "?reqId=" + generateRequestId();

    if (calledBy) {
        url = url + "&calledBy=" + calledBy;
    }

    let customHeaders = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
    }

    if (isCompanyAdmin && parentSupplierId)
        customHeaders.bpsupplierparentid = parentSupplierId;

    let response = {};
    response = await axios.post(url, bodyParams, {
        timeout: config.requestTimeout,
        headers: customHeaders
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return handleRestException(error, response);
        });

    return response;
}

async function remove(url, queryParams, token, isCompanyAdmin, parentSupplierId, calledBy) {
    if (!queryParams) {
        queryParams = [];
    }

    //add mandatory reqId param
    queryParams.push({ name: 'reqId', value: generateRequestId() });

    if (calledBy) {
        queryParams.push({ name: 'calledBy', value: calledBy });
    }

    if (queryParams && queryParams.length) {
        url += '?';
        queryParams.forEach((queryParam, index) => {
            url = url + queryParam.name + '=' + queryParam.value + (index >= queryParams.length - 1 ? '' : '&');
        });
    }

    let customHeaders = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
    }

    if (isCompanyAdmin && parentSupplierId)
        customHeaders.bpsupplierparentid = parentSupplierId;

    //console.log('FINAL URL: ' + url);

    let response = {};
    response = await axios.delete(url, {
        method: 'DELETE',
        timeout: config.requestTimeout,
        headers: customHeaders
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return handleRestException(error, response);
        });

    return response;
}

async function put(url, bodyParams, token, isCompanyAdmin, parentSupplierId, calledBy) {
    //console.log('FINAL URL: ' + url);

    url = url + "?reqId=" + generateRequestId();

    if (calledBy) {
        url = url + "&calledBy=" + calledBy;
    }

    let customHeaders = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
    }

    if (isCompanyAdmin && parentSupplierId)
        customHeaders.bpsupplierparentid = parentSupplierId;

    let response = {};
    response = await axios.put(url, bodyParams, {
        timeout: config.requestTimeout,
        headers: customHeaders
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return handleRestException(error, response);
        });

    return response;
}

async function patch(url, queryParams, token, isCompanyAdmin, parentSupplierId, calledBy) {
    if (!queryParams) {
        queryParams = [];
    }

    //add mandatory reqId param
    queryParams.push({ name: 'reqId', value: generateRequestId() });

    if (calledBy) {
        queryParams.push({ name: 'calledBy', value: calledBy });
    }

    if (queryParams && queryParams.length) {
        url += '?';
        queryParams.forEach((queryParam, index) => {
            url = url + queryParam.name + '=' + queryParam.value + (index >= queryParams.length - 1 ? '' : '&');
        });
    }

    let customHeaders = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
    }

    if (isCompanyAdmin && parentSupplierId)
        customHeaders.bpsupplierparentid = parentSupplierId;

    //console.log('FINAL URL: ' + url);

    let response = {};
    response = await axios.patch(url, {}, {
        timeout: config.requestTimeout,
        headers: customHeaders
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return handleRestException(error, response);
        });

    return response;
}


export default {
    get,
    post,
    remove,
    put,
    patch
}